import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationLoaderService } from '../translation-loader.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-destinations',
  templateUrl: './destinations.component.html',
  styleUrls: ['./destinations.component.scss']
})
export class DestinationsComponent implements OnInit, OnDestroy {
  params$: Subscription;

  constructor(private route: ActivatedRoute,
              private router: Router,
              public translationLoader: TranslationLoaderService) {
  }

  ngOnInit() {
    this.params$ = this.route.params.subscribe(params => {
      this.translationLoader.setDestination(params.dest);
      if (!this.translationLoader.destination) {
        this.router.navigate(['./welcome']);
      }
    });
  }

  ngOnDestroy(): void {
    this.params$.unsubscribe();
  }
}
