<!-- Section: Blog v.3 -->
<section class="my-5" *ngIf="feature">
  <!-- Section heading -->
  <h3 class="h3-responsive font-weight-bold text-center">{{feature.title}}</h3>
  <!-- Section description -->
  <p class="text-center dark-grey-text w-responsive mx-auto mb-5">
    {{feature.description}}
  </p>
  <div class="row">
    <div class="col-md-4 mb-5" *ngFor="let item of getActiveItems()">
      <!-- Card -->
      <mdb-card>
        <div class="view rgba-white-slight waves-light" mdbWavesEffect>
          <!-- Card img -->
          <mdb-card-img [src]="item.image"></mdb-card-img>
          <a>
            <div class="mask"></div>
          </a>
        </div>
        <!--Card content-->
        <mdb-card-body>
          <!--Title-->
          <mdb-card-title>
            <h6><strong>{{item.title}}</strong></h6>
            <p class="small">{{item.price}}</p>
          </mdb-card-title>

          <!--Text-->
          <mdb-card-text *ngFor="let description of item.descriptions">
            <div class="row-description">
              <div class="col-1">
                <i class="{{description.css}}"></i>
              </div>
              <div class="col text-left">
                {{description.title}}
              </div>
            </div>
          </mdb-card-text>
        </mdb-card-body>

        <mdb-card-footer *ngIf="item.button.url" >
          <a class="col-md-12" (click)="book(item.title, item.button.url)" mdbBtn color="primary" mdbWavesEffect>{{item.button.title}}</a>
        </mdb-card-footer>
      </mdb-card>
      <!-- Card -->
    </div>
  </div>
</section>
