import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslationLoaderService } from '../translation-loader.service';
import { TabsetComponent } from 'ng-uikit-pro-standard';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {
  params$: Subscription;
  dest: string;
  type: string;
  types: any;
  @ViewChild('tabs') tabs: TabsetComponent;

  constructor(private route: ActivatedRoute,
              private translationLoader: TranslationLoaderService,
              private translateService: TranslateService) {
  }

  ngOnInit() {
    this.params$ = this.route.params.subscribe(params => {
      this.translationLoader.setDestination(params.dest);
      this.dest = params.dest;
      this.type = params.type;
      this.types = this.getFeaturedTypes().find(type => type.url.toLowerCase() === this.type.toLowerCase());
    });

    this.translateService.onLangChange.subscribe(e => {
      this.types = this.getFeaturedTypes().find(type => type.url.toLowerCase() === this.type.toLowerCase());
    });
  }

  getFeaturedTypes() {
    this.translationLoader.setDestination(this.dest);
    return this.translationLoader.destination.types.filter(t => t.showcases && t.showcases.length > 0);
  }

  ngOnDestroy(): void {
    this.params$.unsubscribe();
  }
}
