import { Component, Input, OnInit } from '@angular/core';

declare let ga: Function;

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent implements OnInit {
  @Input() feature: any;

  constructor() { }

  ngOnInit() {
  }

  getActiveItems() {
    return this.feature.items.filter(i => i.active === 'true');
  }

  book(name: string, url: string) {
    ga('send', {
      hitType: 'event',
      eventCategory: 'Book',
      eventAction: name
    });
    window.open(url, '_blank');
  }
}
