import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MDBBootstrapModulesPro, MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WelcomeComponent } from './welcome/welcome.component';
import { ProductComponent } from './product/product.component';
import { DestinationsComponent } from './destinations/destinations.component';
import { CardComponent } from './destinations/card/card.component';
import { ProductTypeComponent } from './destinations/product-type/product-type.component';
import { ShowcaseComponent } from './product/showcase/showcase.component';
import { FeatureComponent } from './product/feature/feature.component';
import { environment } from '../environments/environment';

export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(http);
  return new TranslateHttpLoader(http, environment.translationUrl, '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    ProductComponent,
    DestinationsComponent,
    CardComponent,
    ProductTypeComponent,
    ShowcaseComponent,
    FeatureComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MDBBootstrapModulesPro.forRoot(),
    AppRoutingModule
  ],
  providers: [
    MDBSpinningPreloader
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
