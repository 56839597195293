<!-- Intro Section -->
<main class="py-3">
  <div class="container">
    <app-card *ngIf="translationLoader.destination"
              [destination]="translationLoader.destination">
    </app-card>

    <!-- Section: Blog v.3 -->
    <section class="my-5" *ngFor="let type of translationLoader.destination.types">
      <app-product-type [productType]="type"
                        [destinationName]="translationLoader.destination.url">
      </app-product-type>

      <hr class="my-5">
    </section>
    <!-- Section: Blog v.3 -->
  </div>
</main>
