<!-- Grid row -->
<div class="row">
  <!-- Grid column -->
  <div class="col-lg-5 col-xl-4">

    <!-- Featured image -->
    <div class="view overlay rounded z-depth-1-half mb-lg-0 mb-4 waves-light" mdbWavesEffect>
      <img class="img-fluid" [src]="productType.image">
      <a>
        <div class="mask rgba-white-slight"></div>
      </a>
    </div>

  </div>
  <!-- Grid column -->

  <!-- Grid column -->
  <div class="col-lg-7 col-xl-8">

    <!-- Post title -->
    <h3 class="font-weight-bold mb-3"><strong>{{productType.name}}</strong></h3>
    <!-- Excerpt -->
    <p class="dark-grey-text">{{productType.description}}</p>
    <!-- Read more button -->
    <a [routerLink]="['/products', destinationName.toLowerCase(), productType.url.toLowerCase()]"
       mdbBtn color="primary" mdbWavesEffect>{{productType.button}}</a>
  </div>
  <!-- Grid column -->

</div>
<!-- Grid row -->
