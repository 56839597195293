import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationLoaderService } from '../../translation-loader.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() destination: any;

  constructor(private translateService: TranslateService,
              private translationLoader: TranslationLoaderService) {
  }

  ngOnInit() {
    this.translateService.onLangChange.subscribe(e => {
      this.destination = this.translationLoader.setDestination(this.destination.url);
    });
  }

}
