import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationLoaderService {
  translation: any;
  header: any;
  welcome: any;
  destinations: any;
  destination: any;

  constructor(private translateService: TranslateService) {
  }

  changeTranslation(lang: string) {
    if (lang === null || lang === undefined) {
      lang = 'en';
    }

    localStorage.setItem('lang', lang);
    this.translateService.use(lang);

    this.translateService.onLangChange.subscribe(translation => {
      this.translation = translation.translations;
      this.header = this.translation.header;
      this.welcome = this.translation.welcome;
      this.destinations = this.translation.destinations;
    });
  }

  setDestination(url: string) {
    this.destination = this.destinations.find(destination => destination.url.toLowerCase() === url.toLowerCase());
    return this.destination;
  }
}
