<!-- Section: Blog v.1 -->
<section class="my-2" *ngFor="let showcase of getActiveShowcases()">
  <!-- Grid row -->
  <div class="row">
    <!-- Grid column -->
    <div class="col-lg-5">
      <!-- Featured image -->
      <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4 waves-light" mdbWavesEffect>
        <img class="img-fluid" src="{{showcase.image}}" alt="Sample image">
        <a>
          <div class="mask rgba-white-slight"></div>
        </a>
      </div>

    </div>
    <!-- Grid column -->

    <!-- Grid column -->
    <div class="col-lg-7">

      <!-- Post title -->
      <h5 class="font-weight-bold mb-3"><strong>{{showcase.title}}</strong></h5>
      <!-- Category -->
      <a class="{{showcase.color}}">
        <h6 class="font-weight-bold mb-3"><i class="pr-2 {{showcase.icon}}"></i>{{showcase.category}}</h6>
      </a>
      <!-- Excerpt -->
      <p>{{showcase.description}}</p>

    </div>
    <!-- Grid column -->

  </div>
  <!-- Grid row -->

  <hr class="my-5">

</section>
<!-- Section: Blog v.1 -->
