<header class="h-100" *ngIf="translationLoader.translation">
  <mdb-navbar SideClass="navbar fixed-top navbar-expand-lg navbar-dark scrolling-navbar default-color"
              [containerInside]="false">
    <mdb-navbar-brand>
      <a routerLink="./">
        <img width="90" src="{{translationLoader.translation.header.logo}}" alt="">
      </a>
    </mdb-navbar-brand>
    <links>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item waves-light" mdbWavesEffect>
          <a class="nav-link" href="https://www.favstay.com">
            {{translationLoader.translation.header.accommodation}}
          </a>
        </li>

        <li *ngFor="let destination of getActiveDestinations()" class="nav-item waves-light" mdbWavesEffect
            routerLinkActive="active">
          <a class="nav-link" routerLink="/destinations/{{destination.url.toLowerCase()}}">
            {{destination.name}}
          </a>
        </li>

      </ul>
      <ul class="navbar-nav nav-flex-icons">
        <li class="nav-item">
          <a class="nav-link" (click)="changeLanguage('cn')">
            <img width="30" src="https://bdteam.blob.core.windows.net/files/services/images/cn.png">
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="changeLanguage('en')">
            <img width="30" src="https://bdteam.blob.core.windows.net/files/services/images/en.png">
          </a>
        </li>
      </ul>
    </links>
  </mdb-navbar>

  <router-outlet (activate)="onActivate($event)"></router-outlet>
</header>
<!--Main Navigation-->



<!-- Footer -->
<footer class="page-footer font-small">
  <div class="footer-copyright text-center py-3"
       style="background-color: #2bbbad;">
      <h6 class="mb-0 text-white">© 2019 Copyright: FavStay.com</h6>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->
