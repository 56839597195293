import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent implements OnInit {
  @Input() showcases: any;

  constructor() {
  }

  ngOnInit() {
  }

  getActiveShowcases() {
    return this.showcases.filter(e => e.active && e.active === 'true');
  }
}
