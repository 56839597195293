<!-- Intro Section -->
<main class="py-3">
  <div class="container">
    <div class="my-4 pt-5">
      <div class="row">
        <ul class="nav justify-content-center lighten-4">
          <li class="nav-item" *ngFor="let type of getFeaturedTypes()">
            <a class="nav-link" routerLink="../{{type.url}}" routerLinkActive="active">
              <h6>{{type.name}}</h6>
            </a>
          </li>
        </ul>
      </div>

      <app-showcase [showcases]="types.showcases"></app-showcase>
      <app-feature [feature]="types.feature"></app-feature>

    </div>
  </div>
</main>
