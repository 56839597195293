import { Component, OnInit } from '@angular/core';
import { TranslationLoaderService } from './translation-loader.service';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(public translationLoader: TranslationLoaderService,
              private router: Router) {
    ga('create', environment.ga, 'auto');
  }

  ngOnInit(): void {
    const lang = localStorage.getItem('lang');
    this.translationLoader.changeTranslation(lang);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects + ` [${lang}]`);
        ga('send', 'pageview');
      }
    });
  }

  changeLanguage(lang: string) {
    ga('set', 'page', this.router.url + ` [${lang}]`);
    ga('send', 'pageview');

    this.translationLoader.changeTranslation(lang);
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  getActiveDestinations() {
    return this.translationLoader.destinations.filter(e => e.active && e.active === 'true');
  }
}
